import React from "react";
import { handsjoin } from "../../images";
import './index.css';

const Card = ({ personInfo, personImage }) => {

  if(!personInfo && !personImage){
    return
  }

  return (
    <div className="text-center items-center mx-auto px-4 max-w-[700px] grid grid-cols-2 py-4 relative ">
      <div>
      <img src={personImage} className="rounded-lg h-auto w-auto aspect-[16/18] " alt="personImage" />
      </div>
        <div className=" bg-white rounded shadow-cardshadow h-[260px] ml-[-40px] p-4   right-12 ">
         <div className="calling w-auto h-[220px]">
          <img src={handsjoin} className="h-[32px] w-[32px] " alt="handsjoin"/>
          <div className='text-left font-semibold text-BIGFONTTEXT'>The Calling</div>
          <div className="text-left  h-[140px] w-auto  read-more ">{personInfo}</div>
         </div>
         
          
        </div>
      
    </div>
  );
};

export default Card;
