import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { logo, logotext } from "../../images";
import './index.css';

const Header = () => {
  const headerHeight = 96;

  

  const [active,setActive] = useState('home');
 const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

const handleContact = () =>{
  
  scrollToSection("contact-footer")
  setActive('contact')
}

const handleAbout = () => {
  scrollToSection("about-section");
  setActive('about')
};

const handleHeader = () => {

  scrollToSection("header-section");
  setActive('home')
};
 
  return (
    <header className="sticky top-0  mx-auto z-[1111]">
      <nav className="nav-wrapper bg-white text-GREY  py-2 md:px-10 md:py-4 md:border-b  md:border-GREYBORDER flex flex-col md:flex-row ">
      <div className=' flex mr-auto py-2 md:py-0 pl-4 md:pl-0 items-center'>
              <img
                src={logo}
                className="h-[48px] w-[48px] aspect-auto md:h-[64px] md:w-[64px] "
                alt="logo"
              />
              <div className=" pl-4   w-[80%] sm:w-[auto]">
              <img src={logotext} className="  w-[80%] sm:w-[auto]" alt="logotext"/>
              <span className="text-base">Karnataka India</span>
              </div>
              
              </div>
        <ul className="flex items-center md:justify-end justify-center border-t px-7 border-t-GREYBORDER md:border-none h-[50px] md:h-auto shadow-headerhsadow md:shadow-none">
         
   
          <li className=" pl-4 focus:bg-red-500">
            <NavLink
              to="/"
              className={`${active === 'home' ? 'text-PRIMARY font-semibold text-base md:text-xl home':'text-primary font-semibold text-base md:text-xl'} `}
              onClick={handleHeader}
            >
              Home
            </NavLink>
          </li>
          <li className=" pl-4">
            <NavLink
              to="/"
              className={`${active === 'about' ? 'text-PRIMARY  font-semibold text-base md:text-xl about':'text-primary font-semibold text-base md:text-xl'} `}
                
              
              onClick={handleAbout}
            >
              About
            </NavLink>
          </li>
          <li className="pl-4 "> 
            <NavLink
              to="/"
              onClick={() =>handleContact()}
              className={`${active === 'contact' ? 'text-PRIMARY  font-semibold text-base md:text-xl contact':'text-primary font-semibold text-base md:text-xl'} `}
            >
              Contact
            </NavLink>
          </li>
       
        
        </ul>
      </nav>
    </header>
  );
};

export default Header;
