import React, { useState } from 'react'
import { cardimage, handsjoin, arrowupright} from '../../images';
import { personalCardData } from '../../config';
import './index.css';
import ButtonWithImg from '../ButtonWithImg';
import DialogModal from '../Dialogbox';
const PersonalCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const  handleViewMore = () =>{
  
    setIsModalOpen(true)
  }
  return (<div className="main-container my-4 pl-[0px] md:pl-[30px]">
  <div className="image-container">
      <img src={cardimage} alt="cardimage" className='rounded-t-md md:rounded-t-none'/>
  </div>
  <div className="text-container bg-DARKGREY rounded-md md:rounded-none my-auto h-fit flex flex-row">
    <div className='wrapper py-5 px-4 md:py-1 lg:py-8 '>
      <div className='flex items-end pb-2 text-GREY font-semibold'><img src={handsjoin} className='h-8 w-8' alt='handsjoin'/>The Calling</div>
      <div className='text-data mb-2'>{personalCardData}</div>
      <ButtonWithImg label={'view more'} img={arrowupright} onClick={() =>handleViewMore()}/>
      <DialogModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
  </div>
</div>)
}

export default PersonalCard