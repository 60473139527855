import React from 'react'
import HeroSection from '../HeroSection'

const Home = () => {
  return (<div>
    <HeroSection/>
    </div>
  )
}

export default Home