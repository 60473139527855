import herologo from './ministry_logo.jpeg';
import photo1 from './photo1.jpeg';
import photo2 from './photo2.jpeg';
import photo3 from './photo3.jpeg';
import photo4 from './photo4.jpeg';
import photo5 from './photo5.jpeg';
import photo6 from './photo6.jpeg';
import photo7 from './photo7.jpeg';
import peopleimage from './people_img.jpg';
import logo from './logo.svg';
import logotext from './ministryname.svg';
import herotext from './herotext.svg';
import heroimage from './heroimage.svg';
import play from './play.svg';
import cardimage from './cardimage.jpeg';
import handsjoin from './handsjoin.svg';
import youtubeicon from './youtubeicon.svg';
import facebook from './facebook.svg';
import twitter from './twitter.svg';
import insta from './instagram.svg';
import linkdin from './linkedin.svg';
import whatsapp from './whatsapp.svg';
import arrowupright from './arrowupright.svg';
import arrow from './arrow.svg';


export {
herologo,
peopleimage,
logo,
logotext,
herotext,
heroimage,
play,
photo2,
photo7,
cardimage,
handsjoin,
youtubeicon,
facebook,
linkdin,
insta,
twitter,
whatsapp,
arrowupright,
arrow,
}

export const sliderImages = 
    [{
        url :photo1,
    },
    {
        url :photo3
    },
    {
        url :photo4,
    },
    {
        url :photo5,
    },
    {
        url :photo6,
    },
    {
        url :photo7,
    },

        
       

    ]

export const socialIcons = [{
    icon : youtubeicon
},
{
    icon : twitter
},
{
    icon : facebook
},
{
icon :insta
},
{
icon : linkdin
}

]
    
