import React from "react";
import { heroimage, herotext } from "../../images";
import './index.css';

const HeroSection = () => {
  return (
    <div className="relative bg-white grid grid-flow-row md:grid-cols-2 md:pl-16 px-6 py-14 " id="header-section">
      <div className="grid grid-flow-row gap-6">
        <div>
        <img src={herotext} className="" alt="herotext"/>
        </div>
         <div className="py-4 text-justify">In my deepest, darkest moments, what really got me through was a prayer. Sometimes my prayer was 'Help me.' Sometimes a prayer was 'Thank you.' What I've discovered is that intimate connection and communication with my creator will always get me through because I know my support, my help, is just a prayer away.</div>
        <div className="flex gap-8 items-center align-middle">
         
          {/* <button className="py-2 w-[40%] text-YELLOW bg-BLACK rounded-lg font-semibold">About</button>
          <button className="py-2 justify-center flex-nowrap w-[40%] flex items-center-middle text-YELLOW rounded-lg border border-GREYBORDER font-semibold "><img src={play}/>View Channel</button> */}
        </div>
      </div>

      <img src={heroimage} className="md:mx-auto " alt="heroimage"/>
    </div>
  );
};

export default HeroSection;
