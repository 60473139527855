const DialogModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay ">
        <div className="modal">
          <button className="close-button" onClick={onClose}>Close</button>
          <div className="modal-content">
           
            <h2>Dialog Modal</h2>
            <p>This is your dialog modal content.</p>
          </div>
        </div>
      </div>
    );
  };

  export default DialogModal;