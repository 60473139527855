import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./index.css";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5 
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 
  },
  mobile: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
    slidesToSlide: 1
  },
  smallmobile: {
    breakpoint: { max: 464, min: 10 },
    items: 1,
    slidesToSlide: 1
  }
};

const Slider = ({images}) => {
 
  return (
    <div className="parent  mx-6 ">
      <Carousel
        responsive={responsive}
        autoPlay={false}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        { images.map((imageUrl, index) => {
          return (
            <div className="slider " key={index}>
              <img src={imageUrl.url} alt={index} className=" aspect-auto object-fill  "/>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default Slider;