import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import HeroSection from "../HeroSection";
import OurStory from "../OurStory";
import YouTubeIframe from "../YouTubeIframe";

import Testimonials from "../Testimonials";

const Layout = () => {
  return (
    <div className="mx-auto bg-white ">
      <Header />
      <HeroSection />
      <OurStory />
      <YouTubeIframe />
      <Testimonials/>
      <Footer />
    </div>
  );
};

export default Layout;
