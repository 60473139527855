import React from "react";
import "./index.css";

import SocialIcon from "../SocialIcon";
import { footerText, socilalinks } from "../../config";
import ButtonWithImg from "../ButtonWithImg";
import { whatsapp } from "../../images";
import { handleExternalLink } from "../../action";

const Footer = () => {
  const handleIcon = (link) => {
    handleExternalLink(link)
  };

const handleWhatsapp = () =>{
  handleExternalLink('https://wa.me/+917026882222')
}
const year = new Date();

  return (
    <footer className=" text-white py-4  mt-[48px] " id='contact-footer'>
      <div className="footer-main mx-6 md:mx-20 p-4 rounded-3xl text-center">
        <div className="social-links flex items-center justify-center">
          {socilalinks.map((ele, key) => (
            <SocialIcon
              iconimg={ele.socialicon}
              onclick={() => handleIcon(ele.weblink)}
              key={key}
            />
          ))}
        </div>

        <div className="font-bold md:text-3xl py-2 text-base sm:text-xl">{footerText[0]}</div>
        <div className="w-[100%] md:w-[60%] flex mx-auto text-center">{footerText[1]}</div>
        <div> All rights reserved <span>{year.getFullYear()}</span> ©</div>
        <div className="mx-auto justify-center items-center mt-4 cursor-pointer px-6 bg-[#2563EB] py-2  rounded w-fit" onClick={() =>handleWhatsapp()}><ButtonWithImg label={'join now'} img={whatsapp} > </ButtonWithImg></div>
      </div>

      {/* <ul className="footer-container flex justify-between footer-main mx-20 p-4 rounded-3xl mb-4">
      
        <li className="left-part">
          <article className="pr-6">
            <div className="text-white">Email: elrohieministryhsn@gmail.com</div>
            <div>Phone number: 7026882222</div>
          </article>
        </li>
        <li className="right-part ">
          <div className="max-w-[200px]">
            Address:- G.D.M Building Rakshana Pura, 2nd Cross R.C Road,
            Hassan-573201
          </div>
        </li>
      </ul> */}
    </footer>
  );
};

export default Footer;
