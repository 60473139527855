import React from 'react'

const SocialIcon = ({onclick, iconimg}) => {
  return (<div onClick={onclick} className='h-[32px] w-[32px] flex align-middle px-1'>
    <img src={iconimg} alt='incoming'/>
   
  </div>
  )
}

export default SocialIcon