import React from 'react'
import Slider from '../Slider'
import { sliderImages } from '../../images'

const Testimonials = () => {

  return (<div className='mx-4 my-4'>
    <div className='font-bold md:text-2xl md:px-11 pb-4 text-base px-0 py-4'>Testimonies</div>
    <div className='slider-wrapper relative'>
        <Slider images={sliderImages}/>
    </div>

  </div>
  )
}

export default Testimonials