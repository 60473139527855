import React from "react";
import ButtonWithImg from "../ButtonWithImg";
import { arrow } from "../../images";
import IframeComponent from "../Iframe";
import { handleExternalLink } from "../../action";

const YouTubeIframe = () => {
  return (<div className="bg-white px-8 md:px-16 py-4">
    <div className="bg-white flex justify-between mb-2 align-middle items-center ">
      <h1 className="md:text-2xl text-BLACK font-bold text-base">Follow us on You tube</h1>
      <ButtonWithImg label={"View Channel"} img={arrow} onClick={()=>{handleExternalLink('https://www.youtube.com/@ElrohieMinistryHassan')}}/>
    </div>
    <section className="grid lg:grid-flow-col justify-around gap-2">
    <IframeComponent src={'https://www.youtube.com/embed/z2UWkOUfuI4?si=V05zsV7pVS8C6kET'}/>
    <IframeComponent src={'https://www.youtube.com/embed/wmXxb769smI?si=fKE1KGBI55uSxEhi'}/>
    <IframeComponent src={'https://www.youtube.com/embed/5KpsC8szK30?si=w3IfX9-GFi--LgyR'}/>
    </section>
    </div>
  );
};

export default YouTubeIframe;
