import React from "react";

const ButtonWithImg = ({ label, img,onClick }) => {
  return (
    <div className="flex">
      <button className="flex capitalize items-center" onClick={onClick}>
        {label}
        <img src={img} className="pl-2 " alt={'button'}/>
      </button>
    </div>
  );
};

export default ButtonWithImg;
