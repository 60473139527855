import { facebook, insta, twitter, youtubeicon } from "./images";

export const socilalinks = [
    {
        weblink : 'https://www.youtube.com/@ElrohieMinistryHassan',
        socialicon: youtubeicon
    },
    {
        weblink : 'https://www.youtube.com/@ElrohieMinistryHassan',
        socialicon: facebook
    },
    {
        weblink : 'https://www.youtube.com/@ElrohieMinistryHassan',
        socialicon: twitter
    },
    {
        weblink : 'https://www.youtube.com/@ElrohieMinistryHassan',
        socialicon: insta
    },

]

export const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    },
  };

export const ourstory = [
  'The Elohim service began through the gospel service, spreading the good news through thousands of villages via newsletters. Through this service, as a special messenger, I have attended many meetings, proclaiming the word of God. Since the inception of this service, we have been helping many poor people. Through this service, God has helped many souls to overcome. From the beginning of this service, God has provided relief and healing to many people in distress and illness.'
];



export const footerText = [
    
   'Join the community today',
    'Experience the joy of spreading Christ\'s love and making a meaningful impact in our community by joining us in fellowship and service.'
    
   
]

export const personalCardData = [
    'The Elohim service began through the gospel service, spreading the good news through thousands of villages via newsletters. Through this service, as a special messenger, I have attended many meetings, proclaiming the word of God. Since the inception of this service, we have been helping many poor people. Through this service, God has helped many souls to overcome. From the beginning of this service, God has provided relief and healing to many people in distress and illness.'
]