import React from 'react';

const IframeComponent = ({ src }) => {
  return (
    <div className='md:w-[320px] md:h-[300px] aspect-auto pt-4 rounded-sm'>
      <iframe
        src={src}
        title="Embedded Content"
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="no"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
};

export default IframeComponent;
