import React from 'react'
import Card from '../Card'
import { herologo } from '../../images'

const Team = () => {
  return (<div>
    <Card personInfo="Anson Saver Dsouza" personImage={herologo}/>
  </div>
  )
}

export default Team