import React from 'react'
import Heading from '../Heading'
import PersonalCard from '../PersonalCard';
import { ourstory } from '../../config';


const OurStory = () => {
  return (<div className='bg-white' id='about-section'>
    <Heading headingText={'Our Story'}/>
    <div className='text-TEXTCOLOR md:px-16 px-6 py-4 text-justify'>{ourstory}</div>
   
    <PersonalCard/>
  </div>
  )
}

export default OurStory