import React from 'react';
import ReactDOM from 'react-dom/client';

import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';
import Layout from './components/Layout';
import './index.css';

import Home from './components/Home';

import Team from './components/Team';


const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout/>}>
      <Route path='/' element={<Home/>}></Route>
    
      <Route path='team' element={<Team/>}></Route>
   
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={routes}/>
  </React.StrictMode>
);

